<template>
  <b-col sm="12" md="12" lg="12" xl="12" class="cardList pl-4 pr-4">
    <div class="d-flex justify-content-center mb-1" style="width: 100%"> 
      <div class="d-flex justify-content-between" style="width: 50%">
          <b-button  
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="outline-danger"
            @click="setSelectedCategory(null)"
          >
            <feather-icon
              v-if="!selectedCategory"
              class="mr-0"
              size="16"
              icon="LoaderIcon"
            />
            <span class="align-middle"> {{ $t('transmission.create.plans_list.general')}} </span>
          </b-button>
          <b-button
            v-for="category in categories" :key="category.id"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            :variant="setPlanCategoryBadgeVariant(category.id)"
            @click="setSelectedCategory(category.id)"
          >
              <!-- class="text-primary" -->
            <feather-icon
              v-if="selectedCategory === category.id"
              class="mr-1"
              size="16"
              icon="LoaderIcon"
            />
            <span class="align-middle">{{ $t(`transmission.create.plans_list.${category.label.toLowerCase()}`) }}</span>
          </b-button>
      </div>
    </div>

    <vue-horizontal responsive :button="false" ref="horizontal" @scroll-debounce="onScroll">
      <b-col sm="12" md="6" lg="6" xl="3" v-if="getSkeletonState">
        <b-card class="card-app-design plan-card">
          <b-skeleton animation="wave" width="30%" class="mb-3"></b-skeleton>
            <div class="d-flex justify-content-center">
              <b-skeleton animation="wave" width="70%" height="20px"></b-skeleton>
            </div>
            <div class="mb-2 d-flex justify-content-center">
              <b-skeleton animation="wave" width="70%" height="35px"></b-skeleton>
            </div>
            <b-skeleton class="mb-2" animation="wave" width="70%" height="20px"></b-skeleton>
            <b-skeleton class="mb-2" animation="wave" width="70%" height="20px"></b-skeleton>
            <b-skeleton class="mb-2" animation="wave" width="70%" height="20px"></b-skeleton>
            <b-skeleton class="ml-1 mr-1 mt-3 mb-0" animation="wave" width="90%" height="37px"></b-skeleton>
        </b-card>
      </b-col>
      <b-col 
        v-else 
        v-for="plan in plans" :key="plan.id"
        :class="(noPlans == true) ? 'wrapper-no-plans' : ''" 
        sm="12" 
        :md="(noPlans==true) ? '12' : '6'" 
        :lg="(noPlans==true) ? '12' : '6'" 
        :xl="(noPlans==true) ? '12' : '3'" 
      >
        <b-card cols="12" class="card-app-design plan-card no-plan-card" v-if="plan.data == false">
          <div class="text-center">
            <b-card-title class="mb-2">
              <h3 class="text-primary">
                {{ $t('transmission.create.plans_list.no_plan') }}
              </h3>
            </b-card-title>
          </div>
        </b-card>
        <b-card v-else class="card-app-design plan-card mt-50" :class="`${plan.id == selected.id ? `server-card-${setPlanCategoryBadgeVariant(plan.enum_category.id)}` : ''}`"> 
          <div class="design-group d-flex justify-content-between mb-1">
            <b-badge
              :variant="setPlanCategoryBadgeVariant(plan.enum_category.id)"
              class="mr-0" 
            >
              {{ $t(`transmission.create.plans_list.${plan.enum_category.label.toLowerCase()}`)}}
            </b-badge>
            <div v-if="selected.id === plan.id">
              <feather-icon
                :class="`text-${setPlanCategoryBadgeVariant(plan.enum_category.id)}`"
                icon="CheckIcon"
                size="20"
              />
              <strong :class="`text-${setPlanCategoryBadgeVariant(plan.enum_category.id)}`">
                {{ $t('transmission.create.plans_list.selected') }}
              </strong> 
            </div>
          </div>
          <div class="text-center">
            <b-card-title class="mb-50">
              <h3 class="text-primary">
                {{ plan.name.toUpperCase() }}
              </h3>
            </b-card-title>
            
            <b-card-text class="font-small-4">
              {{ `${plan.price}` }}
              <div class="text-muted font-small-2 mb-50">
                {{ `${$t('transmission.create.plans_list.extra_request')} ${plan.price_req_extra} `}}
              </div>
            </b-card-text>
          </div>


          <b-col class="design-group text-center mb-75" v-for="(metric, index) in plan.config.metrics.items" :key="`enum_metric_${index}`">
            <div v-if="metric.enum_metric">
              <feather-icon
                :icon="setMetricIcon(metric.enum_metric.id)"
                size="16"
              />
              &nbsp;
            {{ ` ${$t(`transmission.create.plans_list.${metric.enum_metric.label.toLowerCase()}`)}: ${metric.value.toLocaleString()} `}}
            </div>
            <div v-else>
              <feather-icon
                icon="FileIcon"
                size="16"
              />
              &nbsp;
            </div>
          </b-col>
            <!-- {{ ` ${$t(`transmission.create.plans_list.${metric.enum_metric.label.toLowerCase()}`)}: ${metric.value.toLocaleString()} `}} -->


          <!-- button -->
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            block
            @click="setSelectedPlan(plan)"
          >
            {{$t('transmission.create.plans_list.select_plan')}}
          </b-button>
        </b-card>
      </b-col>
    </vue-horizontal>
    <header class="mb-0 d-flex justify-content-center ">
      <nav>
        <b-button @click="prev" :class="{'active': hasPrev, 'inactive': !hasPrev}" class="mr-1" variant="primary">
          <feather-icon
            icon="ChevronLeftIcon"
          />
        </b-button>
        <b-button @click="next" :class="{'active': hasNext, 'inactive': !hasNext}" variant="primary">
          <feather-icon
            icon="ChevronRightIcon"
          />
        </b-button>
      </nav>
    </header>
  </b-col>
</template>

<script>
import VueHorizontal from 'vue-horizontal'
import { BCard, BButton, BBadge, BCardTitle, BCardText, BAvatar, BCol, BSkeleton } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import custom from '@/custom'
import { makeToast } from '@/layouts/components/Popups'
import { mapGetters } from 'vuex'

export default {
  components: {
    VueHorizontal,
    BCard,
    BButton,
    BBadge, 
    BCardTitle, 
    BCardText, 
    BAvatar,
    BCol,
    BSkeleton,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      hasPrev: false,
      hasNext: true,
      plans: [],
      categories: [],
      selected: [],
      selectedCategory: '',
      noPlans: false
    }
  },
  mounted(){
    this.init()
  },
  computed:{
    ...mapGetters([
      "getSkeletonState",
    ])
  },
  methods: {
    init(){
      this.fetchPlans()
      this.fetchPlanCategories()
    },
    prev() {
      this.$refs.horizontal.prev()
    },
    next() {
      this.$refs.horizontal.next()
    },
    onScroll({hasPrev, hasNext}) {
      this.hasPrev = hasPrev
      this.hasNext = hasNext
    },
    fetchPlans(){
      this.$store.dispatch('setSkeletonState', true).then(() => {
        this.$store.dispatch('getAllActivePlans', { plan_category_id: this.selectedCategory } ).then(async (response) => {
          if (response.length != 0) {
            this.noPlans = false
            this.plans = await response
          }else{
            let aux = [
              {
                id: 0,
                data: false
              }
            ]
            this.noPlans = true
            this.plans = aux
          }
          this.$store.dispatch('setSkeletonState', false)

          this.$nextTick(()=>{
            this.setSelectedPlan(this.plans[0])
          })
        })
      }).catch(() => {
         this.$store.dispatch('setSkeletonState', false)
         makeToast( custom.errorMessages[1])
      })
    },
    fetchPlanCategories(){
      this.$store.dispatch('fetchPlanCategories').then((response) => {
        this.categories = response.items
        this.$nextTick(()=>{
          
        })
      })
    },
    setSelectedCategory(categoryId){
      this.selectedCategory = categoryId
      this.fetchPlans()
    },
    setSelectedPlan(plan){
      if(this.selected === plan){
        return 
      }
      this.selected = plan;
      
      this.$store.dispatch('setSelectedPlan', plan)

    },
    setPlanCategoryBadgeVariant(category){
      switch(true){
        case category === 1: {
          return "info";
        }
        case category === 2: {
          return "primary";
        }
        case category === 3: {
          return "warning";
        }
        case category === 4: {
          return "danger";
        }
      }
    },
    setMetricIcon(id){
      switch(true){
        case id === 1: {
          return 'MapPinIcon'
        }
        case id === 2: {
          return 'RadioIcon'
        }
        case id === 3: {
          return 'SendIcon'
        }
      }
    }
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/variables/_variables.scss';
@import '@core/scss/base/bootstrap-extended/_variables.scss';

/* Content styling is done with tailwind postcss @apply for brevity. */
.cardList {
  max-width: 100%;
}

.plan-name{
  font-size: 20px;
}

.dark-layout{
  .plan-card{
    background-color: $backgroundDark;
  }
}

.wrapper-no-plans{
  display: flex;
  align-items: center;
  justify-content: center;
}

.plan-card{
  background-color: $backgroundLight;
  &.no-plan-card{
    width: 50%;
  }
}

.server-card-primary{
  box-shadow: 1px 2px 10px 0px $primary
}
.server-card-warning{
  box-shadow: 1px 2px 10px 0px $warning
}
.server-card-info{
  box-shadow: 1px 2px 10px 0px $info
}

</style>
<template>
  <div>
  <b-overlay :show="hasExecuted" blur="2px" variant="none">
    <template #overlay>
      <div>
        <big><b-spinner variant="success"/></big>
      </div>
    </template>
    <form-wizard
      color="#12e066"
      :title="null"
      :subtitle="null"
      shape="square"
      finish-button-text="Submit"
      back-button-text="Back"
      next-button-text="Next"
      ref="TransmissionFormWizard"
      class="mb-3"
      @on-complete="submitTransmissionGroup"
    >
      <tab-content 
        title="Group Name" 
        icon="feather icon-file-text"
        :before-change="validationFormGroupName"
      >
        <validation-observer
          ref="groupName"
        >
          <b-row>
            <b-col cols="12" class="mb-2">
              <h2 class="text-primary mb-0 mt-1 text-center limits">
                <strong> Let's Start Something Amazing! </strong>
              </h2>
              <h4 class="text-secondary text-center limits">
                Type a name to your new transmission.
              </h4>
            </b-col>
          <b-col md="12" class="d-flex justify-content-center mb-0">
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="username"
                  rules="required"
                >
                  <b-form-input
                    id="name"
                    size="lg"
                    :state="errors.length > 0 ? false:null"
                    placeholder="⇝ HERE !"
                    autofocus
                    v-model="newGroup.name"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="12" class="d-flex justify-content-center">
              <b-img
                :src="imgName"
                height="250px"
                width="600px"
              />
            </b-col>
          </b-row>
        </validation-observer>
      </tab-content>

      <tab-content title="Plan Selection" icon="feather icon-file-text" :before-change="validationPlanSelection">
        <b-row>
          <b-col cols="12" class="mb-2">
            <h2 class="text-primary mb-0 mt-1 text-center limits">
              <strong> What are your limits? </strong>
            </h2>
            <h4 class="text-secondary text-center mb-0 limits">
              Select the plan that suits you best!
            </h4>
          </b-col>
          <b-col md="12" class="d-flex justify-content-center mb-0">
            <plans-list/>
          </b-col>
        </b-row>
      </tab-content>

      <tab-content title="Base Server" icon="feather icon-database" :before-change="validationBaseServerSelection">
        <b-row>
          <b-col cols="12" class="mb-2">
            <h2 class="text-primary mb-0 mt-1 text-center limits">
              <strong> Now let's setup the servers. </strong>
            </h2>
            <h4 class="text-secondary text-center limits">
              Select one of your available Base Servers ↴
            </h4>
          </b-col>
          <b-col md="12" class="d-flex justify-content-center mb-0">
            <servers-list
              :serverType="custom.enumServerType.MySql"
            />
          </b-col>
        </b-row>
      </tab-content>

      <tab-content title="System Log Server" icon="feather icon-book-open" :before-change="validationSystemLogServerSelection">
        <b-row>
          <b-col cols="12" class="mb-2">
            <h2 class="text-primary mb-0 mt-1 text-center limits">
              <strong> Now let's setup the servers. </strong>
            </h2>
            <h4 class="text-secondary text-center  limits">
              Select one of your available Servers for System Log ↴
            </h4>
          </b-col>
          <b-col md="12" class="d-flex justify-content-center mb-0">
            <servers-list
              :serverType="custom.enumServerType.SystemLog"
            />
          </b-col>
        </b-row>
      </tab-content>

      <tab-content title="Processed Log Server" icon="feather icon-server" :before-change="validationProcessedLogServerSelection">
        <b-row>
          <b-col cols="12" class="mb-2">
            <h2 class="text-primary mb-0 mt-1 text-center limits">
              <strong> Now let's setup the servers. </strong>
            </h2>
            <h4 class="text-secondary text-center mb-0 limits">
              Select one of your available Servers for Processed Logs ↴
            </h4>
          </b-col>
          <b-col md="12" class="d-flex justify-content-center mb-0">
            <servers-list
              :serverType="custom.enumServerType.ProcessedLog"
            />
          </b-col>
        </b-row>
      </tab-content>

      <tab-content title="Confirmation" icon="feather icon-check">
        <b-row class="d-flex justify-content-center">
          <h2 class="text-primary mb-0">
            Transmission
          </h2>
        </b-row>

        <validation-observer
          ref="groupName"
          style="width: 100%"
        >
          <b-row class="d-flex justify-content-center ml-2">
            <b-col sm="12" lg="12" xl="6"  md="6" class="mb-1">
              <b-form-group 
                class="mb-2"
                label="Name"
                label-for="name"
              >
                <validation-provider
                  #default="{ errors }"
                  name="name"
                  rules="required"
                >
                  <b-input-group>
                    <b-input-group-prepend is-text>
                      <feather-icon
                        class="cursor-pointer text-info"
                        icon="EditIcon"
                      />
                    </b-input-group-prepend>
                    <b-form-input
                      id="name"
                      size="lg"
                      :state="errors.length > 0 ? false:null"
                      placeholder="⇝ HERE !"
                      v-model="newGroup.name"
                    />
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
        </validation-observer>
             
        <hr>
        <b-col md="12">
          <h2 class="text-primary text-center mb-2 mt-2">
            Selected Plan
          </h2>  
          
          <div class="d-flex justify-content-center">
            <b-card v-if="getSelectedPlan" class="card-app-design plan-card"> 
              <div class="design-group d-flex justify-content-between">
                <b-badge
                  :variant="setPlanCategoryBadgeVariant(getSelectedPlan.enum_category.id)"
                  class="mr-0" 
                  style="font-size: 12pt"
                >
                  {{ getSelectedPlan.enum_category.label }}
                </b-badge>
              </div>
              <div class="text-center">
                <b-card-title class="mt-0 mb-1">
                  <h3 class="text-primary display-4">
                    {{ getSelectedPlan.name.toUpperCase() }}
                  </h3>
                </b-card-title>
                
                <b-card-text class="font-small-4">
                  {{ `${getSelectedPlan.price}` }}
                  <div class="text-muted font-small-2">
                    {{ `Extra Request: ${getSelectedPlan.price_req_extra}`}}
                  </div>
                </b-card-text>
              </div>

              <b-row class="mt-3 d-flex justify-content-between">
                <b-col cols="4" class="design-group text-center" v-for="metric in getSelectedPlan.config.metrics.items" :key="metric.id">
                  <feather-icon
                    icon="CoffeeIcon"
                    size="16"
                  />
                  &nbsp;
                  : {{ ` ${metric.enum_metric ? metric.enum_metric.label : ''}: ${metric.value.toLocaleString()} `}}
                </b-col>
              </b-row>
            </b-card>
          </div>
        </b-col>

         <hr >
          <b-col md="12">
          <h2 class="text-primary text-center mb-2 mt-2">
            Selected Servers
          </h2> 
          </b-col> 
                  
        <b-row class="d-flex justify-content-center">
          <b-col v-if="getSelectedBaseServer" class="mt-2" sm="6" md="3">
            <b-card class="card-app-design server-card"> 
              <div class="design-group d-flex justify-content-between">
                <b-badge
                  :variant="setServerVariantByType(getSelectedBaseServer.enum_type ? getSelectedBaseServer.enum_type.id : '')"
                  class="mr-0  " 
                >
                  {{ getSelectedBaseServer.enum_type ? getSelectedBaseServer.enum_type.label : ''}}
                </b-badge>
              </div>
              <div class="text-center">
                <b-card-title class="mt-1 mb-2">
                  <h3 :class="`text-${setServerVariantByType(getSelectedBaseServer.enum_type ? getSelectedBaseServer.enum_type.id : '')}`">
                    <strong>
                      {{ getSelectedBaseServer.name }}
                    </strong> 
                    <feather-icon
                      :class="`text-${getSelectedBaseServer.is_public ? 'primary' : 'danger'}`"
                      :icon="getSelectedBaseServer.is_public ? 'UnlockIcon' : 'LockIcon'"
                    />
                  </h3>
                </b-card-title>
              </div>

            
              <div class="design-group">
                <ul style="list-style: none">
                  <li class="mb-1">

                  <feather-icon
                    icon="ServerIcon"
                  />
                  &nbsp;
                    Host: {{ getSelectedBaseServer.host }}
                    
                  </li>
                  <li class="mb-1">

                  <feather-icon
                    icon="KeyIcon"
                  />
                  &nbsp;
                    Identifier: {{ getSelectedBaseServer.identifier }}
                  </li>
                </ul>
              </div>
            </b-card>
          </b-col>
          <b-col v-if="getSelectedSystemLogServer" class="mt-2" sm="6" md="3">

            <b-card class="card-app-design server-card"> 
              <div class="design-group d-flex justify-content-between">
                <b-badge
                  :variant="setServerVariantByType(getSelectedSystemLogServer.enum_type ? getSelectedSystemLogServer.enum_type.id : '')"
                  class="mr-0  " 
                >
                  {{ getSelectedSystemLogServer.enum_type ? getSelectedSystemLogServer.enum_type.label : ''}}
                </b-badge>
              </div>
              <div class="text-center">
                <b-card-title class="mt-1 mb-2">
                  <h3 :class="`text-${setServerVariantByType(getSelectedSystemLogServer.enum_type ? getSelectedSystemLogServer.enum_type.id : '')}`">
                    <strong>
                      {{ getSelectedSystemLogServer.name }}
                    </strong> 
                    <feather-icon
                      :class="`text-${getSelectedSystemLogServer.is_public ? 'primary' : 'danger'}`"
                      :icon="getSelectedSystemLogServer.is_public ? 'UnlockIcon' : 'LockIcon'"
                    />
                  </h3>
                </b-card-title>
              </div>
            
              <div class="design-group">
                <ul style="list-style: none">
                  <li class="mb-1">

                  <feather-icon
                    icon="ServerIcon"
                  />
                  &nbsp;
                    Host: {{ getSelectedSystemLogServer.host }}
                    
                  </li>
                  <li class="mb-1">

                  <feather-icon
                    icon="KeyIcon"
                  />
                  &nbsp;
                    Identifier: {{ getSelectedSystemLogServer.identifier }}
                  </li>
                </ul>
              </div>
            </b-card>
          </b-col>
          <b-col v-if="getSelectedProcessedLogServer" class="mt-2" sm="6" md="3">
            <b-card class="card-app-design server-card"> 
              <div class="design-group d-flex justify-content-between">
                <b-badge
                  :variant="setServerVariantByType(getSelectedProcessedLogServer.enum_type ? getSelectedProcessedLogServer.enum_type.id : '')"
                  class="mr-0  " 
                >
                  {{ getSelectedProcessedLogServer.enum_type ? getSelectedProcessedLogServer.enum_type.label : ''}}
                </b-badge>
              </div>

              <div class="text-center">
                <b-card-title class="mt-1 mb-2">
                  <h3 :class="`text-${setServerVariantByType( getSelectedProcessedLogServer.enum_type ? getSelectedProcessedLogServer.enum_type.id : '')}`">
                    <strong>
                      {{ getSelectedProcessedLogServer.name }}
                    </strong> 
                    <feather-icon
                      :class="`text-${getSelectedProcessedLogServer.is_public ? 'primary' : 'danger'}`"
                      :icon="getSelectedProcessedLogServer.is_public ? 'UnlockIcon' : 'LockIcon'"
                    />
                  </h3>
                </b-card-title>
                
              </div>
              
              <div class="design-group">
                <ul style="list-style: none">
                  <li class="mb-1">

                  <feather-icon
                    icon="ServerIcon"
                  />
                  &nbsp;
                    Host: {{ getSelectedProcessedLogServer.host }}
                    
                  </li>
                  <li class="mb-1">

                  <feather-icon
                    icon="KeyIcon"
                  />
                  &nbsp;
                    Identifier: {{ getSelectedProcessedLogServer.identifier }}
                  </li>
                </ul>
              </div>
            </b-card>
          </b-col>
        </b-row> 
      </tab-content>
    </form-wizard>
    </b-overlay>
    <l-overlay />
  </div>
</template>

<script>
import { FormWizard, TabContent } from "vue-form-wizard";
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import vSelect from "vue-select";
import { makeToast } from '@/layouts/components/Popups.js'
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import plansList from "@/layouts/components/plansList";
import serversList from "@/layouts/components/serversList";
import custom from '@/custom'
import { mapGetters } from "vuex";
import LOverlay from "@/layouts/components/LoadingOverlay";

import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BCard,
  BFormSelect,
  BImg,
  BBadge,
  BButton,
  BCardTitle,
  BCardText,
  VBTooltip,
  BInputGroupPrepend,
  BInputGroup,
  BOverlay,
  BSpinner,
} from "bootstrap-vue";

export default {
  components: {
    FormWizard,
    TabContent,
    ValidationProvider,
    ValidationObserver,
    BRow,
    BCol,
    BCard,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BBadge,
    BButton,
    BCardTitle,
    BCardText,
    BInputGroupPrepend,
    BInputGroup,
    vSelect,
    BImg,
    plansList,
    serversList,
    LOverlay,
    BOverlay,
    BSpinner,
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  data() {
    return {
      custom,
      required,
      imgName: require('@/assets/images/pages/group_name3.svg'),
      newGroup: {
        name: null,
      },
      timerHaltOn: false,
      hasExecuted: false
    };
  },
  computed:{
    ...mapGetters([
      "getSelectedPlan",
      "getSelectedBaseServer", 
      "getSelectedSystemLogServer", 
      "getSelectedProcessedLogServer",
      "getLoadingState",
    ])
  },
  mounted () {
    window.addEventListener('keydown', this.registerKey)
    window.addEventListener('keyup', this.unregisterKey)
  },
  beforeDestroy () {
    window.removeEventListener('keydown', this.registerKey)
    window.removeEventListener('keyup', this.unregisterKey);
  },
  methods: {
    registerKey(e){
      switch (e.key.toLowerCase()) {
        case 'enter':
          if (!this.timerHaltOn){
            this.$refs['TransmissionFormWizard'].nextTab()        
            this.timerHaltOn = true
            setTimeout(() => {
              this.timerHaltOn = false
            }, 200);
            break;    
          }
      }      
    },
    validationFormGroupName(){
      return new Promise((resolve, reject) => {
        this.$refs.groupName.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    validationPlanSelection(){
      return new Promise((resolve, reject) => {
        if (this.getSelectedPlan) {
          resolve(true)
        } else {
          makeToast(custom.errorMessages.planSelected)
          reject()
        }
      })
    },
    validationBaseServerSelection(){
      return new Promise((resolve, reject) => {
        if (this.getSelectedBaseServer) {
          resolve(true)
        } else {
          makeToast(custom.errorMessages.baseServerSelected)
          reject()
        }
      })
    },
    validationSystemLogServerSelection(){
      return new Promise((resolve, reject) => {
        if (this.getSelectedSystemLogServer) {
          resolve(true)
        } else {
          makeToast(custom.errorMessages.systemLogServerSelected)
          reject()
        }
      })
    },
    validationProcessedLogServerSelection(){
      return new Promise((resolve, reject) => {
        if (this.getSelectedProcessedLogServer) {
          resolve(true)
        } else {
          makeToast(custom.errorMessages.processedLogServerSelected)
          reject()
        }
      })
    },
    submitTransmissionGroup(){
      if (this.hasExecuted){
        return 
      }
      this.hasExecuted = true;
      this.$store.dispatch('createTransmissionGroup', this.newGroup).then((response) => {        
        this.$router.push({
          name: 'home',
        }).then(() => {
          this.newGroup = null
          makeToast(custom.successMessages.createTransmissionGroup)
        })
      }).catch((error) => {
        console.error(error)
        makeToast( error.code ? custom.errorMessages[error.code] : custom.errorMessages.createTransmissionGroup)
      })
    },
    setPlanCategoryBadgeVariant(category){
      switch(true){
        case category === 1: {
          return "info";
        }
        case category === 2: {
          return "primary";
        }
        case category === 3: {
          return "warning";
        }
        case category === 4: {
          return "danger";
        }
      }
    },
    setServerVariantByType(type){
      switch(true){
        case type === 1: {
          return "primary";
        }
        case type === 2: {
          return "warning";
        }
        case type === 3: {
          return "info";
        }
      }
    },

  },
};
</script>


<style lang="scss">
@import "@core/scss/vue/libs/vue-wizard.scss";
@import "@core/scss/vue/libs/vue-select.scss";
@import '@core/scss/vue/libs/swiper.scss';
</style>

<style lang="scss" scoped>

@import '@/assets/scss/variables/_variables.scss';
@import '@core/scss/base/bootstrap-extended/_variables.scss';

  h1{
    &.limits {
      font-size: 32pt;
    }
  }
  h4{
    &.limits{
      font-size: 17pt
    }
  }

  .cardList {
  max-width: 100%;
}

.plan-name{
  font-size: 20px;
}

.dark-layout{
  .server-card{
    background-color: $backgroundDark;
  }
  .plan-card{
    background-color: $backgroundDark;
  }
}

.server-card-primary{
  box-shadow: 1px 2px 10px 0px $primary
}
.server-card-warning{
  box-shadow: 1px 2px 10px 0px $warning
}
.server-card-info{
  box-shadow: 1px 2px 10px 0px $info
}
.server-card{
  background-color: $backgroundLight;
  cursor: pointer
}
.plan-card{
  background-color: $backgroundLight;
  cursor: pointer;
  width: 45%;
  @media (max-width: 1280px) {
    width: 60%;
  }
  @media (max-width: 991px) {
    width: 80%;
  }
  @media (max-width: 764px) {
    width: 90%;
  }
}




</style>
<template>
  <b-col sm="12" md="12" lg="12" xl="12" class="cardList pl-4 pr-4">
    <vue-horizontal responsive :button="false" ref="horizontal" @scroll-debounce="onScroll">
      <b-col sm="12" md="6" lg="6" xl="3" v-if="getSkeletonState">
        <b-card class="card-app-design server-card">
          <b-skeleton animation="wave" width="30%" class="mb-3"></b-skeleton>
            <div class="d-flex justify-content-center">
              <b-skeleton animation="wave" width="70%" height="20px"></b-skeleton>
            </div>
            <div class="mb-2 d-flex justify-content-center">
              <b-skeleton animation="wave" width="70%" height="35px"></b-skeleton>
            </div>
            <b-skeleton class="mb-2" animation="wave" width="70%" height="20px"></b-skeleton>
            <b-skeleton class="mb-2" animation="wave" width="70%" height="20px"></b-skeleton>
            <b-skeleton class="mb-2" animation="wave" width="70%" height="20px"></b-skeleton>
            <b-skeleton class="ml-1 mr-1 mt-3 mb-0" animation="wave" width="90%" height="37px"></b-skeleton>
        </b-card>
      </b-col>
      <b-col v-else :class="(noServer==true) ? 'wrapper-no-servers' : ''" sm="12" :md="(noServer==true) ? '12' : '6'" :lg="(noServer==true) ? '12' : '6'" :xl="(noServer==true) ? '12' : '3'" v-for="server in servers" :key="server.id">
        <b-card cols="12" class="card-app-design plan-card no-server-card" v-if="server.data == false">
          <div class="text-center">
            <b-card-title class="mb-2">
              <h3 class="text-primary">
                {{$t('transmission.create.base_servers_list.no_server')}}
              </h3>
            </b-card-title>
          </div>
        </b-card>
        <b-card v-else class="card-app-design server-card mt-1" :class="`${server.id == selected.id ? `server-card-${setServerVariantByType(serverType)}` : ''}`"
          @click="setSelectedServer(server)"
        > 
          <div class="design-group d-flex justify-content-between">
            <feather-icon
              :class="`text-${server.is_public ? 'primary' : 'danger'}`"
              :icon="server.is_public ? 'UnlockIcon' : 'LockIcon'"
              size="20"
            />
            <div v-if="selected.id === server.id">
              <feather-icon
                :class="`text-${setServerVariantByType(serverType)}`"
                icon="CheckIcon"
                size="20"
              />
              <strong :class="`text-${setServerVariantByType(serverType)}`">
                {{$t('transmission.create.base_servers_list.selected')}}
              </strong> 
            </div>
          </div> 
          <div class="text-center">
            <b-card-title class="mb-2">
              <h2 :class="`text-${setServerVariantByType(serverType)}`">
                <strong>
                  {{ server.name }}
                </strong> 
                
              </h2>
            </b-card-title>
            
          </div>

          <!-- design group -->
         
          <div class="design-group">
            <ul style="list-style: none">
              <li class="mb-1">

              <feather-icon
                icon="KeyIcon"
              />
                &nbsp;
                {{` ${$t('transmission.create.base_servers_list.identifier')}: ${server.identifier} `}}
              </li>
            </ul>
          </div>
        </b-card>
      </b-col>
    </vue-horizontal>
    <header class="mb-2 d-flex justify-content-center ">
      <nav>
        <b-button @click="prev" :class="{'active': hasPrev, 'inactive': !hasPrev}" class="mr-1" variant="primary">
          <feather-icon
            icon="ChevronLeftIcon"
          />
        </b-button>
        <b-button @click="next" :class="{'active': hasNext, 'inactive': !hasNext}" variant="primary">
          <feather-icon
            icon="ChevronRightIcon"
          />
        </b-button>
      </nav>
    </header>
  </b-col>
</template>

<script>
import VueHorizontal from 'vue-horizontal'
import { BCard, BButton, BBadge, BCardTitle, BCardText, BAvatar, BCol, BSkeleton } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import custom from '@/custom'
import { makeToast } from '@/layouts/components/Popups'
import { mapGetters } from 'vuex'

export default {
  props:{
    serverType:{
      type: Number,
      required: true,
    },
  },
  components: {
    VueHorizontal,
    BCard,
    BButton,
    BBadge, 
    BCardTitle, 
    BCardText, 
    BAvatar,
    BCol,
    BSkeleton,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      hasPrev: false,
      hasNext: true,
      servers: [],
      // serverType: [],
      selected: [],
      selectedServerType: '',
      noServer: false
    }
  },
  mounted(){
    this.init()
  },
  computed:{
    ...mapGetters([
      "getSkeletonState",
    ])
  },
  methods: {
    init(){
      this.fetchServers()
      // this.fetchServerType()
    },
    prev() {
      this.$refs.horizontal.prev()
    },
    next() {
      this.$refs.horizontal.next()
    },
    onScroll({hasPrev, hasNext}) {
      this.hasPrev = hasPrev
      this.hasNext = hasNext
    },
    fetchServers(){
      this.$store.dispatch('setSkeletonState', true).then(() => {
        this.$store.dispatch('getServersByOrganization', { server_type_id: this.serverType } ).then(async (response) => {
          if (response.length != 0) {
            this.noServer = false
            this.servers = await response
          }else{
            let aux = [
              {
                id: 0,
                data: false
              }
            ]
            this.noServer = true
            this.servers = aux
          }
          this.$store.dispatch('setSkeletonState', false)

          this.$nextTick(()=>{
            this.setSelectedServer(this.servers[0])
          })
        })
      }).catch(() => {
         this.$store.dispatch('setSkeletonState', false)
         makeToast( custom.errorMessages[1])
      })
    },
    setSelectedServer(server){
      if(this.selected === server){
        return
      }
      this.selected = server;

      switch(true){
        case this.serverType === 1: {
          this.$store.dispatch('setSelectedBaseServer', this.selected)
          break;
        }
        case this.serverType === 2: {
          this.$store.dispatch('setSelectedSystemLogServer', this.selected)
          break;
        }
        case this.serverType === 3: {
          this.$store.dispatch('setSelectedProcessedLogServer', this.selected)
          break;
        }

      }
    },
    setServerVariantByType(type){
      switch(true){
        case type === 1: {
          return "primary";
        }
        case type === 2: {
          return "warning";
        }
        case type === 3: {
          return "info";
        }
      }
    }
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/variables/_variables.scss';
@import '@core/scss/base/bootstrap-extended/_variables.scss';

/* Content styling is done with tailwind postcss @apply for brevity. */
.cardList {
  max-width: 100%;
}

.plan-name{
  font-size: 20px;
}

.dark-layout{
  .server-card{
    background-color: $backgroundDark;
  }
}

.wrapper-no-servers{
  display: flex;
  align-items: center;
  justify-content: center;
}

.plan-card{
  &.no-server-card{
    width: 50%;
  }
}

.server-card-primary{
  box-shadow: 1px 2px 10px 0px $primary
}
.server-card-warning{
  box-shadow: 1px 2px 10px 0px $warning
}
.server-card-info{
  box-shadow: 1px 2px 10px 0px $info
}
.server-card{
  background-color: $backgroundLight;
  cursor: pointer
}




</style>